<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('portal.sponsored_training') }}  {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" sm="12">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="p_profession_type"
            >
              <template v-slot:label>
                {{$t('portal.applicant_type')}}
              </template>
              <b-form-select
                plain
                v-model="search.p_profession_type"
                :options="professionTypeList"
                id="p_profession_type"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="mobile"
            >
            <template v-slot:label>
              {{$t('globalTrans.mobile')}}
            </template>
            <b-form-input
              plain
              v-model="search.mobile"
              id="mobile"
            ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('portal.sponsored_training') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <!-- <template v-slot:headerAction>
            <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template> -->
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(start_date)="data">
                      {{ data.item.start_date | dateFormat }}
                    </template>
                    <template v-slot:cell(end_date)="data">
                      {{ data.item.end_date | dateFormat }}
                    </template>
                    <template v-slot:cell(mobile)="data">
                      {{ ($i18n.locale === 'bn' ? '০' : '0') + $n(data.item.mobile, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-warning" v-if="data.item.s_training_status == 1">{{$t('elearning_config.pending')}}</span>
                      <span class="badge badge-danger" v-else-if="data.item.s_training_status == 3">{{$t('globalTrans.reject')}}</span>
                      <span class="badge badge-success" v-else>{{$t('globalTrans.approved')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                      <a href="javascript:" v-if="data.item.s_training_status == 1" :title="$t('globalTrans.approve')" v-b-modal.modal-approve-reject class="btn_table_action table_action_status" size="sm" @click="approveReject(data.item.id, 2)"><i class="fa fa-check"></i></a>
                      <a href="javascript:" v-if="data.item.s_training_status == 1" :title="$t('globalTrans.reject')" v-b-modal.modal-approve-reject class="btn_table_action table_action_toggle" size="sm" @click="approveReject(data.item.id, 3)"><i class="fas fa-times"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-5" size="lg" :title="$t('portal.sponsored_training') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <b-button @click="pdfExportDetails" class="ml-4 btn-success water-test-pdf-button">
        {{  $t('globalTrans.export_pdf') }}
      </b-button>
       <Details :item="item" :key="id" ref="details"/>
    </b-modal>
    <b-modal id="modal-approve-reject" size="md" :title="status === 2 ? $t('globalTrans.approve') : $t('globalTrans.reject')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <approveReject :id="itemId" :status="status"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { SponsoredTrainingList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import Details from './Details'
import approveReject from './approveReject.vue'

export default {
  name: 'UiDataTable',
  mixins: [ModalBaseMasterList],
  components: {
   Details, approveReject
  },
  data () {
    return {
      itemId: '',
      status: '',
      search: {
        p_profession_type: 0,
        mobile: ''
      },
      id: 0,
      item: ''
    }
  },
  computed: {
     ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    professionTypeList () {
      const professionTypeList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Government Organization' : 'সরকারি প্রতিষ্ঠান', text_en: 'Government Organization', text_bn: 'সরকারি প্রতিষ্ঠান' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Private Organization' : 'বেসরকারি প্রতিষ্ঠান', text_en: 'Private Organization', text_bn: 'বেসরকারি প্রতিষ্ঠান' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Person' : 'ব্যক্তি', text_en: 'Person', text_bn: 'ব্যক্তি' }
      ]
      return professionTypeList
    },
    officerTypeList: function () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Senior Officer' : 'ঊর্ধ্বতন কর্মকর্তা', text_en: 'Senior Officer', text_bn: 'ঊর্ধ্বতন কর্মকর্তা' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Mid-level Officer' : 'মিড লেভেল কর্মকর্তা', text_en: 'Mid-level Officer', text_bn: 'মিড লেভেল কর্মকর্তা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Junior Officer' : 'জুনিয়র কর্মকর্তা', text_en: 'Junior Officer', text_bn: 'জুনিয়র কর্মকর্তা' }
      ]
    },
    committeeTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.committeeType
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('portal.sponsored_training') + ' ' + this.$t('globalTrans.entry') : this.$t('portal.sponsored_training') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('portal.applicant_type'), class: 'text-center' },
          { label: this.$t('portal.applicant_name'), class: 'text-center' },
          { label: this.$t('globalTrans.mobile'), class: 'text-center' },
          { label: this.$t('portal.training_title'), class: 'text-center' },
          // { label: this.$t('portal.training_start_date'), class: 'text-center' },
          // { label: this.$t('portal.training_end_date'), class: 'text-center w-15' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'applicant_type_bn' },
          { key: 'applicant_name_bn' },
          { key: 'mobile' },
          { key: 'training_title_bn' },
          // { key: 'start_date' },
          // { key: 'end_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'applicant_type' },
          { key: 'applicant_name' },
          { key: 'mobile' },
          { key: 'training_title' },
          // { key: 'start_date' },
          // { key: 'end_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    this.search = Object.assign({}, this.search, {
        org_id: this.$store.state.dataFilters.orgId
    })
    core.index()
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.item = item
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(trainingElearningServiceBaseUrl, SponsoredTrainingList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
        const professionObj = this.professionTypeList.find(doc => doc.value === parseInt(item.p_profession_type))
        const professionData = {}
        if (typeof professionObj !== 'undefined') {
          professionData.applicant_type = professionObj.text_en
          professionData.applicant_type_bn = professionObj.text_bn
        } else {
          professionData.applicant_type = ''
          professionData.applicant_type_bn = ''
        }
        return Object.assign({}, item, professionData)
      })
      return listData
    },
    pdfExportDetails () {
        this.$refs.details.pdfExport()
    },
    approveReject (id, status) {
      this.itemId = id
      this.status = status
    }
  }
}
</script>
<style>
.wid span {
    display: block;
    width: 100px;
    overflow-wrap: anywhere;
}
</style>
